import { graphql, useStaticQuery } from 'gatsby'

// Remember, cannot filter here. Can only use: 'useStaticQuery
const AllProjectsQuery = () => {
  const data = useStaticQuery(graphql`
    query ALL_PROJECTS_QUERY_HOOK {
      allProjectsJson {
        nodes {
          icon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          iconName
          name
          description
          stack
        }
      }
    }
  `)

  return data.allProjectsJson.nodes
}

export default AllProjectsQuery
