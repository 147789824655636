/**@jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import AllProjectsQuery from '../hooks/all-projects-query'

const OtherWork = () => {
  const projects = AllProjectsQuery()

  return (
    <div>
      <h2>Other Work</h2>
      <ul>
        {projects.map(({ id, path, title }) => (
          <li key={id}>
            <Link
              to={path}
              sx={{
                '&.active': {
                  fontStyle: 'italic',
                  textDecoration: 'none',
                  '::after': { content: '" (currently viewing) "' },
                },
              }}
              activeClassName="active"
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default OtherWork
