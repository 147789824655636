import merge from 'deepmerge'
import colors from './colors'
import styles from './styles'

export default merge(
  {},
  {
    useCustomProperties: true,
    initialColorMode: `light`,
    useColorSchemeMediaQuery: true,
    colors,
    breakpoints: ['40em', '52em', '64em'],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    fonts: {
      body: `system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif`,
      heading: `inherit`,
      monospace: `Menlo, monospace`,
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
    fontWeights: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      heading: 700,
      body: 400,
    },
    lineHeights: {
      none: 1,
      normal: 1.5,
      relaxed: 1.625,
      loose: 2,
      body: 1.625,
      heading: 1.25,
    },
    letterSpacings: {
      body: `normal`,
      caps: `0.1em`,
      heading: `0.2em`,
      nav: `0.05em`,
    },
    text: {
      heading: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        letterSpacing: '2px',
        my: 2,
        color: 'heading',
        zIndex: '90',
      },
      subheading: {
        fontFamily: 'heading',
        lineHeight: 'normal',
        fontWeight: 'normal',
        my: 3,
        color: 'subHeading',
      },
      subheadingSecondary: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'medium',
        my: 1,
        color: 'headingSecondary',
      },
      author: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'medium',
        textTransform: 'none',
        my: 1,
        color: 'subHeading',
      },
      callBooking: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'medium',
        fontSize: ['3', '3', '3', '3'],
        textTransform: 'none',
        my: 1,
        color: 'projectCopy',
      },
      authorName: {
        fontFamily: 'monospace',
        textTransform: 'uppercase',
        letterSpacing: 'nav',
        // color: 'subHeading',
      },
      consultingServices: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'normal',
        my: 2,
      },
      body: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'body',
        letterSpacing: 'body',
        my: 2,
      },
      display: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        letterSpacing: 'heading',
        fontSize: [5, 6, 7],
      },
      caps: {
        fontFamily: 'monospace',
        textTransform: 'uppercase',
        letterSpacing: 'caps',
      },
      form: {
        fontFamily: 'monospace',
        textTransform: 'none',
        letterSpacing: 'caps',
        fontWeight: 'semibold',
      },
      about: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'normal',
        my: 4,
      },
      contactFooter: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'normal',
        my: 1,
      },
      projectTypeTitle: {
        color: 'badge',
        fontWeight: 'normal',
      },
      projectTypeTitleHovered: {
        color: 'projectProduct',
        fontWeight: 'normal',
      },
    },
    links: {
      // Theme UI 'Link' variants (defaults set in theme.styles)
      nav: {
        fontFamily: 'body',
        fontSize: ['1', '1', '2'],
        fontWeight: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'nav',
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          color: 'highlighted',
        },
        '&.active': {
          color: 'inherit',
          fontWeight: 'bold',
          bg: 'inherit',
          textDecoration: 'none',
        },
      },
      navMobile: {
        fontFamily: 'body',
        fontSize: ['3'],
        fontWeight: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'nav',
        color: 'mobileNav',
        textDecoration: 'none',
        '&:hover': {
          color: 'highlighted',
        },
        '&.active': {
          color: 'mobileNav',
          fontWeight: 'bold',
          bg: 'inherit',
          textDecoration: 'none',
        },
      },
      navFooter: {
        fontFamily: 'body',
        fontSize: ['3'],
        fontWeight: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'nav',
        color: 'footerNav',
        textDecoration: 'none',
        '&:hover': {
          color: 'highlighted',
        },
        '&.active': {
          color: 'accent',
          fontWeight: 'bold',
          bg: 'inherit',
          textDecoration: 'none',
        },
      },
      secondary: {
        fontWeight: 'bold',
        color: 'inherit',
        textDecoration: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          color: 'yellow',
        },
        '&.active': {
          color: 'highlighted',
        },
      },
      web3: {
        fontFamily: 'body',
        fontSize: ['3', '3', '3', '3'],
        fontWeight: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'nav',
        color: 'headingSecondary',
        textDecoration: 'none',
        '&:hover': {
          color: 'highlighted',
        },
        '&.active': {
          color: 'inherit',
          fontWeight: 'bold',
          bg: 'inherit',
          textDecoration: 'none',
        },
      },
      web3Alt: {
        fontFamily: 'body',
        fontSize: ['3', '3', '3', '3'],
        fontWeight: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'nav',
        color: 'projectCopy',
        textDecoration: 'none',
        '&:hover': {
          color: 'web3links',
        },
        '&.active': {
          color: 'inherit',
          fontWeight: 'bold',
          bg: 'inherit',
          textDecoration: 'none',
        },
      },
    },
    layout: {
      header: {
        position: 'sticky',
        top: '0',
        padding: 3,
        zIndex: 99,
      },
      footer: {
        padding: 4,
        // mt: [4, 5, 6],
        zIndex: 50,
      },
      sectionContainer: {
        zIndex: 90,
      },
      sectionHeroContainerDefault: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        pb: [3, 3, 3],
      },
      headingContainer: {
        textAlign: 'center',
        pt: [4, 5, 6],
      },
      headingContainerContactSection: {
        textAlign: 'center',
        pt: [4, 5, 6],
        maxWidth: ['250px', 'none', 'none'],
      },
      subHeadingContainer: {
        width: ['85%', '70%', '70%'],
        margin: 'auto',
        textAlign: 'center',
        px: [1, 4, 5],
      },
      contactSideBar: {
        my: [4, 4, 4, 4],
        px: [0, 0, 0, 0],
      },
      contactForm: {
        my: [4, 4, 4, 4],
        px: [0, 0, 0, 0],
      },
      contactFormField: {
        my: [3, 3, 3, 3],
        mx: [3, 3, 3, 3],
        px: [0, 0, 0, 0],
        flexDirection: 'column',
      },
      buttonContainerDefault: {
        textAlign: 'center',
        mb: '1',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: ['column', 'row', 'row', 'row'],
      },
      customAccessibility: {
        '&:focus': {
          outline: 'none',
          boxShadow: `0 0 10px 2px rgba(2, 190, 214, 0.6)`,
        },
        '&:active': {
          outline: 'none',
        },
      },
    },
    sizes: {
      container: 1200,
    },
    buttons: {
      primary: {
        minWidth: 200,
        color: colors.background,
        // bg: '#73A580',
        bg: colors.primary,
        border: `1px solid ${colors.primary}`,
        // '&:hover': {
        //   bg: 'background',
        //   color: 'primary',
        // },
      },
      secondary: {
        minWidth: 200,
        color: colors.background,
        '--color-1': colors.highlighted,
        '--color-2': colors.primary,
        background: `
          linear-gradient(
            160deg,
            var(--color-1),
            var(--color-2) 100%
          )
        `,
        '&:hover': {
          bg: 'text',
        },
      },
      gray: {
        minWidth: 200,
        color: colors.background,
        bg: 'gray',
      },
      outline: {
        minWidth: 200,
        color: colors.text,
        // bg: 'yellow',
        bg: colors.card,
        border: `1px solid ${colors.card}`,
        // '&:hover': {
        //   bg: 'primary',
        //   color: 'background',
        // },
      },
      icon: {
        minWidth: 100,
        color: colors.background,
        bg: colors.primary,
      },
      compact: {
        minWidth: 100,
        color: colors.background,
        bg: colors.primary,
        border: `1px solid ${colors.primary}`,
        py: 1,
        px: 2,
      },
      compactFull: {
        width: '100%',
        color: colors.background,
        bg: colors.primary,
        border: `1px solid ${colors.primary}`,
        py: 1,
        px: 2,
      },
      contact: {
        minWidth: 200,
        color: colors.background,
        bg: colors.highlighted,
        border: `1px solid ${colors.highlighted}`,
      },
    },
    grids: {
      primary: {
        gridTemplateRows: [`100px 100px 100px`, `100px`, `100px`],
        justifyContent: 'center',
        mt: [2, 4, 4],
        pt: [2, 3, 3],
        mb: [2, 4, 4],
      },
      secondary: {
        gridTemplateRows: [`100px 100px`, `100px`, `100px`],
        justifyContent: 'center',
        mt: [2, 4, 4],
        mb: [2, 5, 5],
        pb: [2, 2, 2],
      },
      cards: {
        justifyContent: 'center',
        my: [0, 4, 4],
      },
      cardDescription: {
        gridTemplateRows: [`auto 240px auto`, `auto 240px auto`, `auto 240px auto`],
        // justifyContent: 'center',
        // my: ['auto', 'auto', 'auto'],
        display: 'contents',
      },
      projectStack: {
        // gridTemplateRows: [`100px, 100px`, `100px, 100px`, `1fr`],
        justifyContent: 'center',
        my: [1, 1, 1],
      },
    },
    shadows: {
      header: `${colors.muted} 0px 1px 4px 0px`,
      card: `0 0 8px ${colors.muted}`,
    },
    cards: {
      primary: {
        padding: 2,
        borderRadius: 4,
        // boxShadow: 'card',
      },
      secondary: {
        padding: 2,
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'muted',
      },
      compact: {
        padding: 1,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'muted',
      },
    },
    badges: {
      primary: {
        color: 'background',
        bg: 'primary',
        letterSpacing: 'heading',
        m: 1,
        py: 1,
        px: 2,
      },
      secondary: {
        color: 'primary',
        bg: 'badge',
        letterSpacing: 'nav',
        boxShadow: 'inset 0 0 0 1px',
        m: 1,
        py: 0,
        px: 2,
      },
      outline: {
        color: 'badgeText',
        bg: 'badge',
        letterSpacing: 'nav',
        // boxShadow: 'inset 0 0 0 1px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'badgeBorder',
        m: 1,
        py: 0,
        px: 2,
      },
    },
    styles,
  },
)
