const jasmineRouge = `#be4b52`
// const jasmineRougeMedium = `#e5a6a4`
const jasmineRougeLight = `#f8e1e0`
const jasmineRougeBlackOp = `#404040`
const jasmineRougeBlack = `#121923`
// const jasmineRougeBlack = `#231313`
// const jasmineRougeLightGrey = `#f8f8f8`
const jasmineRougeLightGrey = `#f5f5f5`
const jasmineRougeHighlight = `#02BED6`
const darkGray = `hsla(0, 0%, 0%, 0.6)`
const lightGray = `hsla(0, 0%, 0%, 0.1)`
const white = `#fff`
const lightWhite = `rgba(255, 255, 255, 0.86)`
const richBlack = `#444444`
const blueGrayDark = `#515e72`
const blueGray = `#66758d`
const blueGrayLight = `#71819c`
// const redAccent = `#ff0000`
const accent = `#d9e76c`
const patersonColor = `#b7a05e`
const patersonColorDark = `#828383`
const spotexColor = `#88BDBC`
const spotexColorDark = `#88BDBC`
const riskwestColor = `#264870`
const riskwestColorDark = `#264870`
const rfyColor = `#FF859D`
const rfyColorDark = `#2d78bf`
const rfyCopyColorDark = blueGrayDark
const rfyCopyColorLight = lightWhite
const avantiColor = `#DBEBFB`
const avantiColorDark = `#022844`
const avantiCopyColorDark = blueGrayDark
const avantiCopyColorLight = lightWhite
const dwightColor = `#60c657`
const dwightColorDark = `#667eea`
const sauceCandyColor = `#FF6BBD`
const sauceCandyColorDark = `#427DD8`
const kungpaoColor = `#AB1239`
const kungpaoColorDark = `#AB1239`
const neonLight = blueGray
const neonLightShadow = `none`
const neonDark = `hsl(192 100% 95%)`
const neonDarkShadow = `hsl(194 100% 40%)`
const glitchLight = jasmineRougeLightGrey
const glitchDark = jasmineRougeBlack

export default {
  text: blueGrayDark,
  // text: richBlack,
  background: jasmineRougeLightGrey,
  // background: white,
  primary: jasmineRouge,
  secondary: jasmineRougeBlack,
  accent: accent,
  highlighted: jasmineRougeHighlight,
  web3: jasmineRougeHighlight,
  muted: lightGray,
  mutedSecondary: darkGray,
  heading: jasmineRougeBlack,
  headingNeon: neonLight,
  headingNeonShadow: neonLightShadow,
  headingSecondary: jasmineRouge,
  subHeading: blueGray,
  form: lightGray,
  formInput: white,
  formInputBorder: jasmineRouge,
  formLabel: lightGray,
  header: white,
  footer: jasmineRouge,
  icon: blueGrayDark,
  // icon: jasmineRougeBlackOp,
  iconSecondary: jasmineRouge,
  iconFrom: white,
  badge: blueGrayLight,
  badgeBorder: blueGrayLight,
  badgeText: white,
  card: accent,
  externalLinkFrom: darkGray,
  externalLinkTo: jasmineRougeHighlight,
  patersonColor: patersonColor,
  spotexColor: spotexColor,
  riskwestColor: riskwestColor,
  rfyColor: rfyColor,
  rfyCopy: rfyCopyColorDark,
  avantiColor: avantiColor,
  avantiCopy: avantiCopyColorDark,
  dwightColor: dwightColor,
  sauceCandyColor: sauceCandyColor,
  kungpaoColor: kungpaoColor,
  mobileNav: white,
  footerNav: white,
  burgerIcon: jasmineRougeBlackOp,
  burgerIconActive: jasmineRouge,
  carouselButton: jasmineRougeHighlight,
  carouselButtonDisable: lightGray,
  projectCopy: white,
  projectFeatures: richBlack,
  projectProduct: blueGrayLight,
  wave: white,
  waveFooter: jasmineRouge,
  blob: white,
  services: white,
  glitch: glitchLight,
  web3links: jasmineRougeBlack,
  modes: {
    dark: {
      text: lightWhite,
      // background: 'red',
      background: jasmineRougeBlack,
      primary: jasmineRougeBlack,
      secondary: lightWhite,
      muted: lightWhite,
      mutedSecondary: lightGray,
      highlight: jasmineRouge,
      web3: jasmineRougeHighlight,
      heading: lightWhite,
      headingNeon: neonDark,
      headingNeonShadow: neonDarkShadow,
      headingSecondary: accent,
      subHeading: blueGrayLight,
      form: richBlack,
      formInput: jasmineRougeLight,
      formInputBorder: jasmineRougeLight,
      formLabel: richBlack,
      header: jasmineRougeBlackOp,
      footer: blueGrayDark,
      icon: white,
      iconFrom: jasmineRougeBlack,
      badge: blueGrayLight,
      badgeBorder: jasmineRougeBlack,
      badgeText: jasmineRougeBlack,
      card: blueGrayLight,
      externalLinkFrom: jasmineRougeLight,
      externalLinkTo: accent,
      patersonColor: patersonColorDark,
      spotexColor: spotexColorDark,
      riskwestColor: riskwestColorDark,
      rfyColor: rfyColorDark,
      rfyCopy: rfyCopyColorLight,
      avantiColor: avantiColorDark,
      avantiCopy: avantiCopyColorLight,
      dwightColor: dwightColorDark,
      sauceCandyColor: sauceCandyColorDark,
      kungpaoColor: kungpaoColorDark,
      mobileNav: lightWhite,
      footerNav: lightWhite,
      burgerIcon: lightWhite,
      burgerIconActive: accent,
      carouselButton: accent,
      carouselButtonDisable: lightWhite,
      projectCopy: lightWhite,
      projectFeatures: lightWhite,
      projectProduct: richBlack,
      wave: jasmineRougeBlackOp,
      waveFooter: blueGrayDark,
      blob: jasmineRougeBlackOp,
      services: lightWhite,
      glitch: glitchDark,
      web3links: jasmineRougeBlack,
    },
  },
}
