// object is primarily used as a way to style MDX content and helps avoid the need to use global CSS.
// but we can also use the 'Styled' component too that will pick up these styles
// for example we would create our own 'Box' component styles here...
export default {
  root: {
    // top-level styles to the <body> element,
    // uses the theme values provided above
    // To disable applying styles to the <body> element, add the `useBodyStyles: false` flag to your theme.
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
    color: 'text',
    // bg: 'green',
    // We don't set the font-size on the body. Rather we default to the browser defaul of 16px.
    // Our breakpoints are 'em' units and thus based on the browser default of 16px. This way we also handle anyone that changes their
    // browser's default font-size
  },
  h1: {
    fontSize: [5, 7, 8],
    my: 1,
    mx: [2, 2, 2],
  },
  h2: {
    fontSize: [5, 6, 7],
    my: 1,
    mx: 1,
  },
  h3: {
    fontSize: [4, 5, 6],
    my: 1,
    mx: 1,
  },
  h4: {
    fontSize: [3, 4, 5],
    my: 1,
    mx: 1,
  },
  h5: {
    fontSize: [2, 3, 4],
    my: 1,
    mx: 1,
    lineHeight: [1, 1.25, 1.25],
  },
  h6: {
    fontSize: [1, 1, 2],
    my: 1,
    mx: 1,
    // lineHeight: [1, 1.25, 1.25],
  },
  p: {
    variant: 'text.body',
    fontSize: 2,
    my: 1,
    mx: 1,
  },
  pre: {
    fontFamily: 'monospace',
    overflowX: 'auto',
    code: {
      color: 'inherit',
    },
  },
  code: {
    fontFamily: 'monospace',
    // fontSize: 'inherit',
    fontSize: [3, 3, 3],
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  th: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  td: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  // by default the Theme UI 'Link' component will use these styles, unless overrideen
  // in the Theme UI style index component
  a: {
    fontWeight: 'bold',
    color: 'highlighted',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    '&:hover': {
      color: 'yellow',
    },
    '&.active': {
      color: 'highlighted',
    },
  },
  // links: {
  //   // used by the NavLink component
  //   bold: {
  //     fontWeight: 'bold',
  //     color: 'highlighted',
  //   },
  //   nav: {
  //     fontWeight: 'bold',
  //     color: 'inherit',
  //     textDecoration: 'none',
  //     textTransform: 'uppercase',
  //     '&.active': {
  //       color: 'primary',
  //     },
  //   },
  // },
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 99999,
    },
    stack: {
      width: 48,
      height: 48,
      borderRadius: 99999,
    },
  },
}
