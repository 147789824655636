import merge from 'lodash.merge'
import baseTheme from 'gatsby-theme-marketing/src/gatsby-plugin-theme-ui'

export default merge({}, baseTheme, {
  layout: {
    // header: {
    //   color: 'blue',
    //   bg: 'pink',
    //   position: 'sticky',
    //   top: '0'
    // },
    // footer: {
    //   color: 'white',
    //   backgroundColor: 'black',
    // },
  },
})